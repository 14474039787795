import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["labSelect", "labTests", "labTestRow"];
  declare labSelectTarget: HTMLSelectElement;
  declare labTestsTarget: HTMLElement;
  declare labTestRowTargets: HTMLElement[];

  connect() {
    this.displayLabTestsForSelectedLab(this.labSelectTarget.value);
  }

  selectLab(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedLab = target.value;

    this.displayLabTestsForSelectedLab(selectedLab);
  }

  displayLabTestsForSelectedLab(selectedLab: string) {
    if (this.labTestRowTargets.length === 0) return;

    this.labTestRowTargets.forEach((row) => {
      const checkbox = row.querySelector(
        "input[type=checkbox]"
      ) as HTMLInputElement;
      if (row.dataset.destination === selectedLab) {
        row.classList.remove("hidden");
        if (row.dataset.defaultInUi === "true") {
          checkbox.checked = true;
        }
      } else {
        checkbox.checked = false;
        row.classList.add("hidden");
      }
    });

    if (selectedLab) {
      this.showLabTests();
    } else {
      this.hideLabTests();
    }
  }

  showLabTests() {
    this.labTestsTarget.classList.remove("hidden");
  }

  hideLabTests() {
    this.labTestsTarget.classList.add("hidden");
  }
}
